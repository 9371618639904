import { render } from '@react-email/render'
import React, { useState } from 'react'
import { styled } from 'linaria/react'
import MaxWidth from '../Layout/MaxWidth'
import { CategoryName } from '../CategoryPage/CategoryHeader'
import { theme } from '../Theme'
import { Formik, Form, Field } from 'formik'
import Spinner from '../ui/Spinner'

const Wrapper = styled(MaxWidth)`
  padding: 50px 20px;
`
const Content = styled('div')`
  text-align: center;
  max-width: 800px;
  margin: auto;
  h1 {
    color: ${theme.colors.brown};
    margin-top: 20px;
    text-align: center;
    font-size: 30px;
  }
  p {
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 1rem;
  }
  a {
    color: ${theme.colors.brown};
    text-decoration: underline;
  }
`

const StyledForm = styled(Form)`
  max-width: 700px;
  width: 100%;
  margin: auto;
  svg {
    width: 20px;
    height: 20px;
  }
  .form-inputs {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }
  .form-item {
    display: flex;
    margin-bottom: 20px;
    justify-content: end;
    flex-direction: column;
    label {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    input,
    select {
      display: block;
      width: 100%;
      background: #f2f0eb7d;
      border: 0;
      height: 40px;
      padding: 0 10px;
      &[type='file'] {
        background: none;
        padding: 0;
        margin-top: 10px;
      }
    }
    button {
      background: ${theme.colors.brown};
      color: white;
      font-size: ${theme.fontSizes.tinyParagraph};
      font-weight: ${theme.fontWeights.semibold};
      letter-spacing: 0.1em;
      padding: 0 1rem;
      height: 40px;
      text-transform: uppercase;
      font-weight: bold;
      width: 100%;
      max-width: 300px;
      margin: auto;
      display: block;
    }
  }
`

const SubmittedWrapper = styled('div')`
  text-align: center;
  h2 {
    font-family: ${theme.fonts.title};
    font-size: 20px;
    font-weight: normal;
    margin: 50px 0;
  }
`

const TextContent = () => {
  return (
    <Content>
      <CategoryName>Reklamasjonsskjema</CategoryName>
      <p>
        Noen ganger er ikke produktet helt som du skulle ønske, vi ønsker å
        hjelpe deg raskest mulig med din reklamasjon.
      </p>
      <p>
        Her har du reklamasjonsskjema som går rett til vår serviceavdeling.
        Responstid på reklamasjon er for tiden ca 3 virkedager. Du vil bli
        kontaktet så snart reklamasjonen er løst. For å få sendt skjemaet må du
        sende med bilde av varen.
      </p>

      <p>
        Har du trøbbel med skjemaet kan du sende mail direkte til{' '}
        <a href="mailto:reklamasjon@homeandcottage.no">
          reklamasjon@homeandcottage.no
        </a>
      </p>
    </Content>
  )
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

const HtmlMessage = ({ values }) => {
  return (
    <div>
      <p>
        <strong>Navn:</strong> {values.name} {values.lastname}
      </p>
      <p>
        <strong>Adresse:</strong> {values.address}
      </p>
      <p>
        <strong>Postnummer:</strong> {values.zipcode}
      </p>
      <p>
        <strong>Poststed:</strong> {values.city}
      </p>
      <p>
        <strong>Telefon:</strong> {values.phone}
      </p>
      <p>
        <strong>E-post:</strong> {values.email}
      </p>
      <p>
        <strong>Ordrenummer:</strong> {values.ordernr}
      </p>
      <p>
        <strong>Reklamasjonsårsak:</strong> {values.reason}
      </p>
      <p>
        <strong>Hvilken butikk er varen kjøpt i:</strong> {values.store}
      </p>
      <p>
        <strong>Beskrivelse:</strong> {values.message}
      </p>
      <p>
        <strong>Kjøpsdato:</strong> {values.buydate}
      </p>
      <p>
        <strong>Dato feil/skade ble oppdaget:</strong> {values.breakdate}
      </p>
      <p>
        <strong>
          Kan en løsning på din reklamasjon være en kompensasjon i kroner?:
        </strong>{' '}
        {values.money}
      </p>
    </div>
  )
}

/**
 * The ReturnForm function is a form that allows the user to return an item.
 * The function takes no arguments and returns a Formik component with initial values, validation, and onSubmit functions.
 
 *
 *
 * @return A formik form
 *
 * @docauthor Trelent
 */
const ReturnForm = () => {
  const [submitted, setSubmitted] = useState(false)
  return (
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        let files = []

        if (values.file1) {
          files.push(await readFileAsync(values.file1))
        }
        if (values.file2) {
          files.push(await readFileAsync(values.file2))
        }

        const emailHtml = render(<HtmlMessage values={values} />)
        const req = {
          message: emailHtml,
          html_message: emailHtml,
          receiver: values.email,
          subject: 'Reklamasjonsskjema',
          files: files,
          cc: values?.email
        }

        fetch('/api/sendmail', {
          method: 'POST',
          body: JSON.stringify(req)
        })
          .then(response => response.json())
          .then(result => {
            setSubmitting(true)
            setSubmitted(true)
            return result.response
          })
          .catch(error => console.log('error', error))
      }}
    >
      {({ isSubmitting, setFieldValue, status }) => (
        <StyledForm>
          {submitted ? (
            <SubmittedWrapper>
              <h2>Takk for din reklamasjon!</h2>
            </SubmittedWrapper>
          ) : (
            <>
              <div className="form-inputs">
                <div className="form-item">
                  <label>Fornavn</label>
                  <Field type="text" required name="name" />
                </div>
                <div className="form-item">
                  <label>Etternavn</label>
                  <Field type="text" required name="lastname" />
                </div>
                <div className="form-item">
                  <label>Adresse</label>
                  <Field type="text" required name="address" />
                </div>
                <div className="form-item">
                  <label>Postnummer</label>
                  <Field type="text" required name="zipcode" />
                </div>
                <div className="form-item">
                  <label>Sted</label>
                  <Field type="text" required name="city" />
                </div>
                <div className="form-item">
                  <label>Telefonnummer</label>
                  <Field type="text" required name="phone" />
                </div>
                <div className="form-item">
                  <label>
                    E-post vil kun bli brukt ifbm. ditt kundeforhold hos oss
                  </label>
                  <Field type="email" required name="email" />
                </div>
                <div className="form-item">
                  <label>Ordrenummer</label>
                  <Field type="text" required name="ordernr" />
                </div>

                <div className="form-item">
                  <label>Reklamasjonsårsak</label>
                  <Field as="select" name="reason">
                    <option></option>
                    <option>Varen var ødelagt ved ankomst.</option>
                    <option>Varen var knust ved ankomst.</option>
                    <option>Annen skade/reklamasjon.</option>
                  </Field>
                </div>

                <div className="form-item">
                  <label>Hvilken butikk er varen kjøpt i</label>
                  <Field as="select" name="store">
                    <option></option>
                    <option value="Kristiansand, Sørlandssenteret">
                      Kristiansand, Sørlandssenteret
                    </option>
                    <option value="Lillehammer, Strandtorget senter">
                      Lillehammer, Strandtorget senter
                    </option>
                    <option value="Sandvika Storsenter">
                      Sandvika Storsenter
                    </option>
                    <option value="Stavanger-Tvedtsenteret">
                      Stavanger-Tvedtsenteret
                    </option>
                    <option value="Down Town">Down Town</option>
                    <option value="CC-Vest">CC-Vest</option>
                    <option value="Bergen, Åsane Senter">
                      Bergen, Åsane Senter
                    </option>
                    <option value="Jessheim Storsenter ">
                      Jessheim Storsenter{' '}
                    </option>
                    <option value="Ålesund, Moa">Ålesund, Moa</option>
                    <option value="Vinterbro">Vinterbro</option>
                    <option value="Sarpsborg, Storbyen Kjøpesenter">
                      Sarpsborg, Storbyen Kjøpesenter
                    </option>
                    <option value="Nettbutikken">Nettbutikken</option>
                    <option value="Sentrallager - Jessheim">
                      Sentrallager - Jessheim
                    </option>
                    <option value="Tønsberg">Tønsberg</option>
                    <option value="Torggata">Torggata</option>
                    <option value="Drammen, Gulskogen Kjøpesenter">
                      Drammen, Gulskogen Kjøpesenter
                    </option>
                    <option value="Alna senter, Alnabru">
                      Alna senter, Alnabru
                    </option>
                    <option value="Sandefjord">Sandefjord</option>
                    <option value="Bergen, Lagunen storsenter">
                      Bergen, Lagunen storsenter
                    </option>
                    <option value="Trondheim, Sirkus Shopping">
                      Trondheim, Sirkus Shopping
                    </option>
                    <option value="Strømmen Storsenter">
                      Strømmen Storsenter
                    </option>
                  </Field>
                </div>

                <div className="form-item">
                  <label>Beskrivelse</label>
                  <Field type="text" name="message" />
                </div>

                <div className="form-item">
                  <label>Kjøpsdato</label>
                  <Field type="date" name="buydate" />
                </div>
                <div className="form-item">
                  <label>Dato feil/skade ble oppdaget</label>
                  <Field type="date" name="breakdate" />
                </div>

                <div className="form-item">
                  <label>
                    Kan en løsning på din reklamasjon være en kompensasjon i
                    kroner?
                  </label>
                  <Field as="select" name="money">
                    <option></option>
                    <option>Ja</option>
                    <option>Nej</option>
                  </Field>
                </div>

                <div className="form-item">
                  <label>Last opp bilde av din reklamasjon her.</label>
                  <Field
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={event => {
                      setFieldValue('file1', event.currentTarget.files[0])
                    }}
                  />
                </div>

                <div className="form-item">
                  <label>Last opp bilde av din reklamasjon her.</label>
                  <Field
                    type="file"
                    accept="image/*"
                    name="image2"
                    onChange={event => {
                      setFieldValue('file2', event.currentTarget.files[0])
                    }}
                  />
                </div>
              </div>

              <div className="form-item">
                <button type="submit">
                  {isSubmitting ? <Spinner /> : 'Sende'}
                </button>
              </div>
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  )
}

const Returns = () => {
  return (
    <Wrapper>
      <TextContent />

      <ReturnForm />
    </Wrapper>
  )
}

export default Returns
